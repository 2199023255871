<template>
<div>
    <!-- Top Domain Details -->
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="domain-info mb-8">
                <!-- Domain -->
                <select-filter labelname="Domain" :options="domainOptions" optionsName="domainName" :value="domainSelected" @input="
              newDomain => {
                domainSelected = newDomain;
              }
            "></select-filter>
            <!-- Date Picker -->
                <date-range-picker :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="
              newFromDate => {
                fromDate = newFromDate;
              }
            " @inputToDate="
              newToDate => {
                toDate = newToDate;
              }
            "></date-range-picker>
            <!-- Only Companies checkbox -->
                <only-company :onlyCompaniesValue="onlyCompanies" @input="
              newonlyCompanies => {
                onlyCompanies = newonlyCompanies;
              }
            "></only-company>
            <!-- Update -->
                <vs-button class="bg-actionbutton" type="filled" @click="updateData()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
            </div>
        </div>
    </div>
    <!-- Card -->
    <div class="vx-row">  
        <!-- First Time Visitors -->
        <div class="vx-col xl:w-1/3 mb-8 md:w-1/2 w-full lg-cus-6">
            <single-card icon="UserIcon" :smallTitle="firstTimeVisitors.name ? firstTimeVisitors.name : 'First Time Visitors'" :totalValue="firstTimeVisitors.start" :bottomText="firstTimeVisitors.end + ' total visitors'" bgcolor="bg-first-gradient" maintextcolor="text-first" textcolor="text-secondary" :upDownValue="firstTimeVisitors.percentage" Upicon="ArrowUpIcon" bgimg="user.svg"></single-card>
        </div>
        <!-- Return Visitors -->
        <div class="vx-col xl:w-1/3 mb-8 md:w-1/2 w-full lg-cus-6">
            <single-card icon="CornerLeftUpIcon" :smallTitle="returnVisitors.name" :totalValue="returnVisitors.start" :bottomText="returnVisitors.end + ' total visitors'" bgcolor="bg-second-gradient" maintextcolor="text-second" textcolor="text-secondary" :upDownValue="returnVisitors.percentage" Upicon="ArrowUpIcon" bgimg="user.svg"></single-card>
        </div>
        <!-- Bounced Visitors -->
        <div class="vx-col xl:w-1/3 mb-8 md:w-1/2 w-full lg-cus-6">
            <single-card icon="CornerRightDownIcon" :smallTitle="bouncedVisitors.name" :totalValue="bouncedVisitors.start" :bottomText="bouncedVisitors.end + ' total visitors'" bgcolor="bg-third-gradient" maintextcolor="text-third" textcolor="text-secondary" :upDownValue="bouncedVisitors.percentage" Upicon="ArrowUpIcon" bgimg="user.svg"></single-card>
        </div>
    </div>
    <!-- Visitor Engagement Overview -->
    <div class="vx-row">
        <div class="vx-col w-full mb-8">
            <three-xaxis-chart
                :chartTitle="'Visitor Engagement Overview'"
                :chartType="'line'"
                :chartHeight="'400'"

                :seriesNameOne="'Bounced Visitors'"
                :seriesDataOne="engagementoverviewChartSeriesDataOne"
                
                :seriesNameTwo="'Return Visitors'"
                :seriesDataTwo="engagementoverviewChartSeriesDataTwo"

                :seriesNameThree="'First Time Visitors'"
                :seriesDataThree="engagementoverviewChartSeriesDataThree"
                
                :optionsXaxis="engagementoverviewChartOptionsXaxis"
            ></three-xaxis-chart>
            <!-- <vx-card>
                <div class="card-title">
                    <h2>Visitor Engagement Overview</h2>
                </div>
                <vue-apex-charts type="line" height="400" :options="engagementoverviewChart.chartOptions" :series="engagementoverviewChart.series"></vue-apex-charts>
            </vx-card> -->
        </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import HelpModal from "../../components/modal/HelpModal";
import SingleCard from "../../components/SingleCard";
/* chart */
import ThreeXaxisChart from '../../components/charts/ThreeXaxisChart.vue';

export default {
    components: {
        VxCard,
        SelectFilter,
        DateRangePicker,
        OnlyCompany,
        SingleCard,
        HelpModal,
        ThreeXaxisChart
    },
    data() {
        return {
            /* Domain Filter */
            domainSelected: {
                domainName: "All"
            },
            //Domain dropdown options
            domainOptions: [],
            //From Date
            fromDate: null,
            //To Date
            toDate: null,
            //Only Companies
            onlyCompanies: false,
            /* Popup */
            popupActive: false,

            /* Visitors card data  */
            firstTimeVisitors: {
                name: null,
                start: 0,
                end: 0,
                percentage: 0
            },
            returnVisitors: {
                name: null,
                start: 0,
                end: 0,
                percentage: 0
            },
            bouncedVisitors: {
                name: null,
                start: 0,
                end: 0,
                percentage: 0
            },
            /* Visitor Engagement Overview */
            engagementoverviewChartSeriesDataOne: [],
            engagementoverviewChartSeriesDataTwo: [],
            engagementoverviewChartSeriesDataThree: [],
            engagementoverviewChartOptionsXaxis: [],
            // engagementoverviewChart: {
            //     series: [{
            //             name: "Bounced Visitors",
            //             type: "column",
            //             data: []
            //         },
            //         {
            //             name: "Return Visitors",
            //             type: "area",
            //             data: []
            //         },
            //         {
            //             name: "First Time Visitors",
            //             type: "line",
            //             data: []
            //         }
            //     ],
            //     chartOptions: {
            //         colors: [
            //             token[uid].themeDetail.tempChartColor1,
            //             token[uid].themeDetail.tempChartColor2,
            //             token[uid].themeDetail.tempChartColor3
            //         ],
            //         stroke: {
            //             width: [0, 3, 3],
            //             curve: "smooth"
            //         },
            //         plotOptions: {
            //             bar: {
            //                 columnWidth: "30%",
            //                 endingShape: "rounded"
            //             }
            //         },
            //         fill: {
            //             type: "solid",
            //             opacity: [1, 0.2, 1],
            //             gradient: {
            //                 type: "vertical",
            //                 shadeIntensity: 0,
            //                 opacityFrom: 1,
            //                 opacityTo: 0.2,
            //                 stops: [0, 90, 100]
            //             }
            //         },
            //         markers: {
            //             size: 0
            //         },
            //         xaxis: {
            //             type: "datetime",
            //             categories: [],
            //             labels: {
            //                 hideOverlappingLabels: true,
            //                 showDuplicates: false,
            //                 format: "M/d"
            //             }
            //         },
            //         legend: {
            //             position: "bottom",
            //             fontSize: "12px",
            //             fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
            //             itemMargin: {
            //                 horizontal: 20,
            //                 vertical: 10
            //             },
            //             onItemClick: {
            //                 toggleDataSeries: true
            //             },
            //             markers: {
            //                 width: 12,
            //                 height: 12,
            //                 radius: 2
            //             }
            //         },
            //         grid: {
            //             show: true,
            //             borderColor: "#f2f2f2",
            //             strokeDashArray: 3,
            //             position: "back",
            //             xaxis: {
            //                 lines: {
            //                     show: false
            //                 }
            //             }
            //         },
            //         yaxis: [{
            //                 axisTicks: {
            //                     show: true
            //                 },
            //                 axisBorder: {
            //                     show: true,
            //                     color: token[uid].themeDetail.tempChartColor1
            //                 },
            //                 labels: {
            //                     style: {
            //                         color: token[uid].themeDetail.tempChartColor1
            //                     }
            //                 },
            //                 title: {
            //                     text: "Bounced Visitors",
            //                     style: {
            //                         color: token[uid].themeDetail.tempChartColor1
            //                     }
            //                 },
            //                 tooltip: {
            //                     enabled: true
            //                 }
            //             },
            //             {
            //                 seriesName: "Return Visitors",
            //                 opposite: true,
            //                 axisTicks: {
            //                     show: true
            //                 },
            //                 axisBorder: {
            //                     show: true,
            //                     color: token[uid].themeDetail.tempChartColor2
            //                 },
            //                 labels: {
            //                     style: {
            //                         color: token[uid].themeDetail.tempChartColor2
            //                     }
            //                 },
            //                 title: {
            //                     text: "Return Visitors",
            //                     style: {
            //                         color: token[uid].themeDetail.tempChartColor2
            //                     }
            //                 }
            //             },
            //             {
            //                 seriesName: "First Time Visitors",
            //                 opposite: true,
            //                 axisTicks: {
            //                     show: true
            //                 },
            //                 axisBorder: {
            //                     show: true,
            //                     color: token[uid].themeDetail.tempChartColor3
            //                 },
            //                 labels: {
            //                     style: {
            //                         color: token[uid].themeDetail.tempChartColor3
            //                     }
            //                 },
            //                 title: {
            //                     text: "First Time Visitors",
            //                     style: {
            //                         color: token[uid].themeDetail.tempChartColor3
            //                     }
            //                 }
            //             }
            //         ],
            //         tooltip: {
            //             shared: true,
            //             intersect: false,
            //             x: {
            //                 show: true,
            //                 format: "M/d"
            //             }
            //         }
            //     }
            // }
        };
    },
    created() {
        this.setFromDate();
        this.setToDate();
        this.getEngagementData();
        this.getZoneListForFilter();
    },
    methods: {
        closepopup() {
            this.popupActive = false;
        },
        updateData() {
            this.getEngagementData();
        },
        //Get Domain
        async getZoneListForFilter() {
            this.$vs.loading();
            await this.axios
                .get("/ws/Zone/GetZoneListForFilter")
                .then(response => {
                    let data = response.data;
                    for (let i = 0; i < data.length; i++) {
                        this.domainOptions.push({
                            domainName: data[i]
                        });
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get engagement data between the dates
        async getEngagementData() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsEngagement/GetEngagementData", input)
                .then(response => {
                    let data = response.data;
                    this.firstTimeVisitors.name = data.FirstTimeVisitors.StatName;
                    this.firstTimeVisitors.start = data.FirstTimeVisitors.StatStartValue;
                    this.firstTimeVisitors.end = data.FirstTimeVisitors.StatEndValue;
                    this.firstTimeVisitors.percentage = data.FirstTimeVisitors.StatChangePercent;

                    this.returnVisitors.name = data.ReturnVisitors.StatName;
                    this.returnVisitors.start = data.ReturnVisitors.StatStartValue;
                    this.returnVisitors.end = data.ReturnVisitors.StatEndValue;
                    this.returnVisitors.percentage = data.ReturnVisitors.StatChangePercent;

                    this.bouncedVisitors.name = data.BounceRate.StatName;
                    this.bouncedVisitors.start = data.BounceRate.StatStartValue;
                    this.bouncedVisitors.end = data.BounceRate.StatEndValue;
                    this.bouncedVisitors.percentage = data.BounceRate.StatChangePercent;

                    let x = [];
                    let firstTimeVisitors = [];
                    let returnVisitors = [];
                    let bouncedVisitors = [];

                    data.ChartData.forEach(element => {
                        // let dd = new Date(element.x);
                        // let year = dd.getFullYear();
                        // let month = dd.getMonth() + 1;
                        // let day = dd.getDate();
                        // let date = month + "/" + day + "/" + year;
                        // x.push(date);
                        x.push(element.x);
                        firstTimeVisitors.push(element.First_Time_Visitors);
                        returnVisitors.push(element.Return_Visitors);
                        bouncedVisitors.push(element.Bounced_Visitors);
                        // this.engagementoverviewChart.chartOptions = {
                        //     ...this.engagementoverviewChart.chartOptions,
                        //     ...{
                        //         xaxis: {
                        //             type: "datetime",
                        //             categories: x,
                        //             labels: {
                        //                 hideOverlappingLabels: true,
                        //                 showDuplicates: false,
                        //                 format: "M/d"
                        //             }
                        //         }
                        //     }
                        // };
                    });

                    this.engagementoverviewChartSeriesDataOne = bouncedVisitors
                    this.engagementoverviewChartSeriesDataTwo = returnVisitors
                    this.engagementoverviewChartSeriesDataThree = firstTimeVisitors
                    this.engagementoverviewChartOptionsXaxis = x

                    // this.engagementoverviewChart.series = [{
                    //         name: "Bounced Visitors",
                    //         type: "column",
                    //         data: bouncedVisitors
                    //     },
                    //     {
                    //         name: "Return Visitors",
                    //         type: "area",
                    //         data: returnVisitors
                    //     },
                    //     {
                    //         name: "First Time Visitors",
                    //         type: "line",
                    //         data: firstTimeVisitors
                    //     }
                    // ];
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        }
    }
};
</script>
